.error-page {
    width: 100%;
    height: 100%;
    z-index: 999;
    position: absolute;
    top: 0;
    left: 0;
    background: $background-green;
    .error-img {
        max-width: 85%;
        margin-bottom: 25px;
        @media screen and (min-width: map-get($grid-breakpoints, 'sm')) {
            max-width: 75%;
            margin-bottom: 30px;
        }
        @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
            max-width: 65%;
        }
        @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
            max-width: 50%;
        }
        @media screen and (min-width: map-get($grid-breakpoints, 'xl')) {
            max-width: 45%;
        }
        @media screen and (min-width: map-get($grid-breakpoints, 'xxl')) {
            max-width: 35%;
        }
    }
    .error-message {
        text-transform: uppercase;
        color: $default-green;
        margin-bottom: 10px;
    }
}

.error {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .error-message {
        text-transform: uppercase;
        margin-bottom: 50px;
        text-align: center;
    }
    .error-message-small {
        margin-top: 30px;
        margin-bottom: 20px;
        text-align: center;
    }
    .error-img {
        max-width: 60%;
        @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
            max-width: 50%;
        }
        @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
            max-width: 50%;
        }
        @media screen and (min-width: map-get($grid-breakpoints, 'xl')) {
            max-width: 35%;
        }
        @media screen and (min-width: map-get($grid-breakpoints, 'xxl')) {
            max-width: 25%;
        }
    }
}

.input-error {
    margin: 10px auto;
    background: $lightest-pink;
    border-radius: 5px;
    width: 100%;
    p {
        color: $default-pink;
        padding: 20px;
    }
}
