.heatmap-container {
  display: inline-block;
  margin: 20px;
}

.heatmap-row {
  display: flex;
}

.heatmap-box {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 16px;
  border: 1px solid #ddd;
  text-align: center;
}

.heatmap-header-box {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  background-color: #f2f2f2;
  border: 1px solid #ddd;
  text-align: center;
  cursor: pointer;
}

.heatmap-header-name-box {
  width: 100px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  background-color: #f2f2f2;
  border: 1px solid #ddd;
  text-align: center;
}

.heatmap-name-box {
  width: 100px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
  border: 1px solid #ddd;
  text-align: center;
}

.header {
  margin-bottom: 5px;
}
