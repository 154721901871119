

.css-o4b71y-MuiAccordionSummary-content {
    p {
        font-weight: 600;
        font-size: 16px;
    }
}

.css-11bpk76-MuiPaper-root-MuiAccordion-root {
    padding: 10px;
}
.css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
    p {
        color: #00B0A0
    }
}

.css-yw020d-MuiAccordionSummary-expandIconWrapper {
    border-radius: 5px;
    border: 1px solid #dcdcdc;
}

.css-11bpk76-MuiPaper-root-MuiAccordion-root .Mui-expanded {
    background: none !important;
}

.css-yw020d-MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    color: #00B0A0;
}
