$table-header: #EEEEEE;

.MuiDataGrid-root{
    border: none !important;
    display: grid !important;
    margin-bottom : 15px;
    min-height: 350px;
    border-right: none !important;
    border-radius : 0 !important
}

.MuiDataGrid-columnHeaders{
    background-color :$table-header;
    border-radius : 0;
}
.MuiDataGrid-columnHeadersInner{
    font-size : 14px !important;
    font-weight : bolder !important;
    text-align : center !important;
    font-family: 'Raleway', sans-serif;
}
.css-3be3ve-MuiFormControl-root-MuiTextField-root-MuiDataGrid-toolbarQuickFilter {
    width : 100% !important;
    margin : 5px 10px 5px 10px !important;
    background-color : $table-header;
}

.MuiInput-underline{
    border-bottom : $medium-green !important;
    &:hover{
        border-bottom : $dark-green !important;
    }
}

.MuiDataGrid-toolbarContainer{
    background-color : $table-header;
}

.MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-columnHeader:focus{
    outline : none !important;
    @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
        padding: 15px;
    }
    &:hover {
        @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
            background-color: $light-green;
            border: 2px solid $light-green;
        }
    }
    &.active{
        @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
            background: $light-green;
        }
    }
}
.MuiDataGrid-row{
    border: 1px solid $lighter-grey;
    &:hover {
        @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
            background-color: $light-green;
            border: 2px solid $light-green;
        }
    }
    &.active{
        @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
            background: $light-green;
        }
    }
    .basic-info {
        .name-location{
            text-transform: capitalize;
        }
        display: flex;
    }
    .initials {
        text-transform: uppercase;
        .initials-checkbox {
            color: $white;
            background: $grey;
            width: 40px;
            height: 40px;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            &.green {
                background: $default-green;
            }
            &.yellow {
                background: $default-yellow;
            }
            &.purple {
                background: $default-purple;
            }
        }
    }
}
.MuiDataGrid-cell{
    border : 0 !important;
    outline : none !important;
}
.MuiDataGrid-cell .MuiDataGrid-cell--textLeft .MuiDataGrid-withBorderColor{
    outline : none !important;
    @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
        padding: 15px;
    }
    .basic-info {
        .name-location{
            text-transform: capitalize;
        }
        display: flex;
    }
    .invite-pending{
        margin-right:10px;
    }
    .match {
        display: flex;
        justify-content: center;
        align-items: center;
        p {
            &.green {
                color: $default-green;
            }
            &.yellow {
                color: $default-yellow;
            }
                &.purple {
                color: $default-purple;
            }
        }
    }
    .actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        img {
            cursor: pointer;
            width: 15px;
            height: 15px;
            margin-right: 5px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
}
.MuiDataGrid-cell:focus-within, .MuiDataGrid-cell:focus{
    outline : none !important;
    border : 0 !important;
}
.MuiDataGrid-footerContainer{
    display : none !important;
}

.MuiDataGrid-sortIcon{
    opacity: inherit !important;
    color : $medium-green !important;
}
.custom-row-class {
    background-color: #f0f4f4; /* Apply your custom styles here */
    /* Add more styles as needed */
  } 
