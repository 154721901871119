.inactive-alert{
    background: $dark-grey !important;
    color:$white !important;
    text-align : center !important;
    font-family: Raleway !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    justify-content:center !important;
    .MuiAlert-icon {
        color: $white !important;
    }
}

.onboarding-page {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    align-items: center;
    height: 95dvh;
    .onboarding-page-wrapper {
        min-width: 50%;
        margin: auto;
        .copy {
            margin-bottom: 30px;
        }
        .steps-wrapper {
            .step {
                display: flex;
                margin-bottom: 30px;
                .number-wrapper {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 20px;
                    .number {
                        position: absolute;
                    }
                }
            }
        }

        .button-wrapper {
            margin-bottom: 50px;
        }
    }
}

.onboarding-page-help-center {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    align-items: flex-start;
    height: 95dvh;
    .onboarding-page-wrapper {
        width: 45%;
        margin-right: 40px;
        .copy {
            margin-bottom: 30px;
        }
        .steps-wrapper {
            .step {
                display: flex;
                margin-bottom: 30px;
                .number-wrapper {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 20px;
                    .number {
                        position: absolute;
                    }
                }
            }
        }

        .button-wrapper {
            margin-bottom: 50px;
        }
    }
}

