* {
  box-sizing: border-box;
}

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

// Use like so
// @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
//     your code
// }


// wrappers
.section {
  padding-top: 15px;
  padding-bottom: 15px;
  margin: 30px 0;
}

// responsive components

.desktop {
  display: none;
  @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
      display: block;
  }
}
.mobile {
  display: block;
  @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
      display: none;
  }
}

// Alignment

.flex {
  display: flex;
  &.flex-col {
    flex-direction: column;
  }
  &.flex-row {
    flex-direction: row;
  }
}

.center-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &.row {
    flex-direction: row;
  }
}

.align-right {
  display: flex;
  justify-content: flex-end;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

// margin-padding

.p-0 {
  padding: 0;
}
.h-700 {
  max-height: 700px;
}

.p-20 {
  padding: 20px;
  &.top {
    padding: 20px 0 0 0;
  }
  &.bottom {
    padding: 0 0 20px 0;
  }
  &.left {
    padding: 0 0 0 20px;
  }
  &.right {
    padding: 0 20px 0 0;
  }
  &.top-bottom {
    padding: 20px 0;
  }
}

.m-0 {
  margin: 0;
}

.mar-45 {
  margin: 45px;
  &.top {
    margin: 45px 0 0 0;
  }
  &.bottom {
    margin: 0 0 45px 0;
  }
  &.left {
    margin: 0 0 0 45px;
  }
  &.right {
    margin: 0 45px 0 0;
  }
}

.mar-25 {
  margin: 25px;
  &.top {
    margin: 25px 0 0 0;
  }
  &.bottom {
    margin: 0 0 25px 0;
  }
  &.left {
    margin: 0 0 0 25px;
  }
  &.right {
    margin: 0 25px 0 0;
  }
}

.mar-20 {
  margin: 20px;
  &.top {
    margin: 20px 0 0 0;
  }
  &.bottom {
    margin: 0 0 20px 0;
  }
  &.left {
    margin: 0 0 0 20px;
  }
  &.right {
    margin: 0 20px 0 0;
  }
}

.mar-15 {
  margin: 15px;
  &.top {
    margin: 15px 0 0 0;
  }
  &.bottom {
    margin: 0 0 15px 0;
  }
  &.left {
    margin: 0 0 0 15px;
  }
  &.right {
    margin: 0 15px 0 0;
  }
}

.m-10 {
  margin: 10px;
  &.top {
    margin: 10px 0 0 0;
  }
  &.bottom {
    margin: 0 0 10px 0;
  }
  &.left {
    margin: 0 0 0 10px;
  }
  &.right {
    margin: 0 10px 0 0;
  }
}

.mar-2 {
  margin: 2px;
  &.top {
    margin: 2px 0 0 0;
  }
  &.bottom {
    margin: 0 0 2px 0;
  }
  &.left {
    margin: 0 0 0 2px;
  }
  &.right {
    margin: 0 2px 0 0;
  }
}

.mar-5 {
  margin: 5px;
  &.top {
    margin: 5px 0 0 0;
  }
  &.bottom {
    margin: 0 0 5px 0;
  }
  &.left {
    margin: 0 0 0 5px;
  }
  &.right {
    margin: 0 5px 0 0;
  }
}

.mar-7 {
  margin: 5px;
  &.top {
    margin: 7px 0 0 0;
  }
  &.bottom {
    margin: 0 0 7px 0;
  }
  &.left {
    margin: 0 0 0 7px;
  }
  &.right {
    margin: 0 7px 0 0;
  }
}

li {
  list-style: none;
}
