// .loader-div {
//     position: relative;
//     top: -600px;
//     right: -470px;
// }
.loader-button {
    position: absolute;
    top: 40px;
    right: 40px;
}
.loader-img {
   height: 30px; 
   width: 30px;
}
.m-left {
    margin-left: 10px;
}
.button-menu {
    min-width: 280px !important;
}