.auth-desktop-decoration {
    height: 100%;
    .decoration-wrapper {
        background-color: $default-green;
        position: relative;
        height: 100%;
        overflow: hidden;
        .blob {
            position: absolute;
            &.lighter {
                width: 80%;
                top: 0;
                bottom: 0;
                position: absolute;
                margin: auto;
                left: 0;
                right: 0;
                text-align: center;
                transform: scaleX(-1);
            }
            &.darker {
                left: 8rem;
                top: -6rem;
                width: 145%;
                transform: scaleY(-1);                   
            }
        }
        .illustration-wrapper {
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .illustration {
                max-width: 100%;
                @media screen and (min-width: map-get($grid-breakpoints, 'xxl')) {
                    max-width: 70%;
                }
            }
        }
    }
}