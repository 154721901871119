.experience-wrapper{
    display: flex;
    background-color: #fff;
    padding: 25px;
    border-radius: 12px;
    width: 100%;
}
.experience {
    @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
        // border-left: 1px solid $grey;
    }
    .experience-card {
        .dot {
            width: 19px;
            height: 19px;
            display: block;
            position: absolute;
            border-radius: 50%;
            left: -22px;
            top: 3px;
            border: 2px solid white;
            &.green {
                background: $default-green;
            }
            &.purple {
                background: $default-purple;
            }
        }
        .industry {
            background: $light-grey;
            padding: 5px 20px;
            border-radius: 25px;
        }
    }
}
