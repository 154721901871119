.team-review-page {
    width: 100%;
    .teams-no-data {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .no-data-illustration {
            max-width: 100%;
            margin-bottom: 30px;
        }
        .description {
            width: 400px;
        }
    }
    .team-review-page-wrapper {
        .team-review-desktop {
            display: block;
            width: auto;
            overflow: auto;
            margin: 0 1rem 0 1rem;
            // @media screen and (min-width: map-get($grid-breakpoints, 'xxl')) {
                .add-team {
                    background: $white;
                    width: 100%;
                    margin-top: 30px;
                    border-radius: 10px 10px 0 0;
                    padding: 20px;
                    display: flex;
                    justify-content: flex-end;
                    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
                }
                .teams-filters {
                    display: flex;
                    background: $background-green;
                    .filter {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        &.teams {
                            width: 15%;
                        }
                        &.team-members {
                            width: 16%;
                        }
                        .filter-title {
                            margin: 0 5px 0 0;
                            font-size: 12px;
                        }
                    }
                    .spacer {
                        width: 55%;
                    }
                    @media screen and (max-width: map-get($grid-breakpoints, 'md')){
                            display: none !important;

                    }
                }
                .teams-wrapper {
                    background: $white;
                    .open-positions {
                        display: grid;
                        grid-template-columns: 0.8fr 1.2fr;
                        margin: 15px;
                        .position-items {
                            .position-items-wrapper {
                                border: 1px solid $lighter-grey;
                                .open-position {
                                    display: flex;
                                    align-items: center;
                                    width: 100%;
                                    background-color: $white;
                                    border: none;
                                    border-top: 1px solid $lighter-grey;
                                    padding: 15px;
                                    &.active{
                                background: $light-green;
                            }
                                    &:first-child{
                                        border: none;
                                    }
                                    .position-status {
                                        width: 36px;
                                        height: 36px;
                                        border-radius: 50%;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        img {
                                            width: 25px;
                                            height: 25px;
                                        }
                                        &.active {
                                            background-color: $default-green;
                                        }
                                        &.on-hold {
                                            background-color: $default-yellow;
                                        }
                                        &.inactive {
                                            background-color: $default-pink;
                                        }
                                    }
                                    .position-desctription {
                                        width: 32%;
                                        display: flex;
                                        margin-left: 10px;
                                        .position-title {
                                            overflow-wrap: break-word;
                                            width: 100%;
                                            text-align: left;
                                        }
                                    }
                                    .mazhr-card-filled-candidates {
                                        width: 35%;
                                        display: flex;
                                        flex-direction: column;
                                        align-items: center;
                                    }
                                    .deadline {
                                        width: 25%;
                                        display: flex;
                                        flex-direction: column;
                                        align-items: flex-end;
                                    }
                                    .assessments-taken-candidates {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        .candidates-number {
                                            display: flex;
                                            flex-direction: column;
                                            align-items: center;
                                            margin-right: 10px;
                                        }
                                        img {
                                            width: 25px;
                                            height: 25px;
                                        }
                                    }
                                }
                            }
                        }
                        .edit-field {
                            padding: 5px 0 0 40px;
                            border-top: 1px solid $lighter-grey;
                            border-right: 1px solid $lighter-grey;
                            .tab-content{
                                .date{
                                    margin-right: 10px;
                                }
                            }
                            .open-positions-edit {
                                .basic-info {
                                    display: flex;
                                    justify-content: space-between;
                                    margin: 0 0 20px 0;
                                    .calls-to-action {
                                        .buttons {
                                            margin-bottom: 10px;
                                            button {
                                                &:first-child {
                                                    margin-right: 10px;
                                                }
                                            }
                                        }
                                        .copyJobLink {
                                            display: flex;
                                            justify-content: flex-end;
                                        }
                                    }
                                }
                                .team-deadline {
                                    display: flex;
                                    width: 100%;
                                    justify-content: space-between;
                                    align-items: flex-end;
                                }
                                .description {
                                    margin-right: 10px;
                                }
                                .recruitment-process {
                                    margin-right: 10px;
                                }

                            }
                        }
                    }
                }
            // }
        }
    }
}

.teams {
    display: grid;
    grid-template-columns: 0.8fr 1.2fr;
    margin: 15px;
    .teams-items {
        .teams-wrapper {
            border: 1px solid $lighter-grey;
            .team {
                display: flex;
                align-items: center;
                width: 100%;
                background-color: $white;
                border: none;
                border-top: 1px solid $lighter-grey;
                padding: 23px 20px;
                &.active{
                    background: $light-green;
                }
                &:first-child{
                    border: none;
                }
                .team-title {
                    width: 36%;
                    display: flex;
                    margin-left: 10px;
                    overflow-wrap: break-word;
                    text-align: left;
                }
                .team-count {
                    width: 38%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
                .removeWrapper {
                    width: 25%;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    .bin {
                        width: 20px;
                    }
                }
            }
        }
    }
    .edit-field {
        padding: 10px 40px;
        border-top: 1px solid $lighter-grey;
        border-right: 1px solid $lighter-grey;
        .tab-content{
            .date{
                margin-right: 10px;
            }
        }
        .open-positions-edit {
            .basic-info {
                display: flex;
                justify-content: space-between;
                margin: 0 0 20px 0;
                .calls-to-action {
                    .buttons {
                        margin-bottom: 10px;
                        button {
                            &:first-child {
                                margin-right: 10px;
                            }
                        }
                    }
                    .copyJobLink {
                        display: flex;
                        justify-content: flex-end;
                    }
                }
            }
            .description {
                margin-right: 10px;
            }
        }
    }
}

.title-and-target-profile {
    display: flex;
    align-items: flex-end;
    margin-right: 10px;
    .title{
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        padding: 10px;
        font-size: 16px;
        line-height: 32px;
        color: $black;
        margin-right: 30px;
        width: 35%;
    }
    .department{
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        padding: 10px;
        font-size: 16px;
        line-height: 32px;
        color: $black;
    }
    .targetProfile {
        .MuiBox-root {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }
    }
}

.team-members-section {
    .heading{
        color: $black;
        border: none;
        text-transform: uppercase;
        outline: none;
        font-size: 14px;
        font-weight: 700;
    }
    .invite-emails-list{
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        padding: 10px;
        font-size: 16px;
        line-height: 32px;
        color: $black;
        .emails-field{
            .MuiInput-root{
                width: 50%;
            }
            .MuiFormHelperText-root{
                font-family: 'Raleway';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                color: $dark-grey;
            }

        }
    }
    .teamMembers {
        margin-top: 25px;
        padding-left: 0;
        .teamMember {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            padding: 20px 15px;
            border: 1px solid $default-green;
            border-radius: 5px;
            &.pending {
                border: 1px solid $default-yellow;
            }
            &.rejected {
                border: 1px solid $default-pink;
            }
            .title {
                display: flex;
                align-items: center;
                .initials {
                    width: 35px;
                    height: 35px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 5px;
                    color: $white;
                    margin-right: 10px;
                    background-color: $default-green;
                }
                .name {
                    text-transform : capitalize;
                    font-weight: 700;
                    margin: 0 10px;
                }
                .email {
                    margin: 0 10px;
                }
            }
            .actions {
                display: flex;
                align-items: baseline;
                .icon {
                    cursor: pointer;
                    &.email {
                        height: 28px;
                        width: auto;
                        margin-right: 10px;
                        color : $default-yellow;
                    }
                    &.copy-url {
                        height: 26px;
                        width: auto;
                        margin-right: 10px;
                        color : $medium-green;
                    }
                    &.bin {
                        height: 28px;
                        width: auto;
                        color : $default-pink;
                    }
                }
            }
        }
    }
    .inviteteamMember {
        .text-field{
            margin-right:15px;
        }
        .invite-button{
            min-width : 120px;
        }
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px dashed $grey;
        border-radius: 5px;
        padding: 10px 15px;
    }
}

.key-analytics-wrapper {
    background-color: $white;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
    @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
        padding: 50px;
    }
    .analytics-section {
        padding: 10px;
        width: 100%;
        border: 1px solid $light-grey;
        border-radius: 12px;
        @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
            padding: 30px;
        }
        @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
            width: 49%;
        }
        .individual-analytics-link{
            text-decoration: underline;
            cursor: pointer;
            text-align : end;
            color:$default-green;
            &:hover{
                color:$dark-green
            }
        }
    }
}

.salesProcessHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.MuiStepper-root{
    width : 50%;
    margin : 0 auto;
}

.MuiBackdrop-root{
    position: relative !important;
    z-index: 999;
    height: 70vh;
    opacity:0.5%;
}
.tabs-styling {
    position: absolute;
    top: 100px;
    width: -webkit-fill-available;
}
