// Link

button{
    &.link {
        background: no-repeat;
        border: none;
        color: $default-green;
        &:hover{
            color: $dark-green;
        }
        &.grey {
            cursor: pointer;
            color: $grey;
            &:hover{
                color: $dark-grey!important;
            }
        }
    }
}

.link {
    background: no-repeat;
    border: none;
    color: $default-green;
    text-decoration: none;
    &:hover{
        color: $dark-green;
    }
    &.grey {
        cursor: pointer;
        color: $grey;
        &:hover{
            color: $dark-grey!important;
        }
    }
}

// Regular buttons

.mazhrBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 7px;
    padding: 10px 20px;
    width: fit-content;
    height: fit-content;
    cursor: pointer;
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    &.upper {
        text-transform: uppercase;
    }
    &.full-width {
        width: 100%;
    }
    &.tall {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    &.default-green {
        background-color: $default-green;
        color: $white;
        p {color: $white;}
        a {color: $white;}
        span {color: $white;}
        &:hover {
            background-color: $dark-green;
        }
    }
    &.default-pink {
        background-color: $default-pink;
        color: $white;
        p {color: $white;}
        a {color: $white;}
        span {color: $white;}
        &:hover {
            background-color: $dark-pink;
        }
    }
    &.light-green {
        background-color: $light-green;
        color: $default-green;
        &:hover {
            background-color: $default-green;
            color: $white;
        }
    }
    &.default-yellow {
        background-color: $default-yellow;
        color: $white;
        &:hover {
            background-color: $medium-yellow;
            color: $white;
        }
    }
    &.default-purple {
        background-color: $default-purple;
        border: 1px solid $default-purple;;
        color: $white;
        width: 200px;
    }
    &.default-green-200 {
        background-color: $default-green;
        border: 1px solid $default-green;;
        color: $white;
        width: 200px;
    }
    &.default-green-border {
        background-color: $white;
        color: $default-green;
        p {color: $default-green;}
        a {color: $default-green;}
        span {color: $default-green;}
        border: 1px solid $default-green;
        &:hover {
            background-color: $default-green;
            color: $white;
            p {color: $white;}
            a {color: $white;}
            span {color: $white;}
        }
    }
    &.default-white-border {
        width: 200px;
        background-color: $white;
        color: $black;
        p {color: $black;}
        a {color: $black;}
        span {color: $black;}
        border: 1px solid $grey;
    }
    &.dotted-line-border {
        color: $default-green;
        border: 1px dashed $default-green;
        border-radius: 5px;
        background: $white;
        &:hover {
            background-color: $light-green;
        }
    }
    &.light-grey {
        background: $grey;
        color: $white;
    }
    &.lighter-grey {
        color: $lighter-grey;
    }
}

// Dropdown

.dropdownWrapper {
    &.full-width {
        width: 100%;
    }
    .fullLengthDropdown {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: transparent!important;
        padding: 0;
        color: $black;
        border: none;
        &:hover {
            background-color: transparent;
            color: $black;
        }
        &:active {
            color: $black!important;
            background-color: transparent!important;
            border: none!important;
            box-shadow: none!important;
        }
        &:focus {
            color: $black!important;
            background-color: transparent!important;
            border: none!important;
            box-shadow: none!important;
        }
        &:focus-visible {
            color: $black!important;
            background-color: transparent!important;
            border: none!important;
            box-shadow: none!important;
        }
    }
    .options-bar {
        width: 100%;
        .dropdown-item {
            color: $black;
            &.active {
                background-color: $lighter-green!important;
                font-weight: 100;
            }
            &:active {
                background: transparent!important;
            }
        }
    }
    select {
        width: 100%;
        border: none;
    }
}

.dropdown-menu {
    width: 100%;
    cursor: pointer;
    option {
        padding: 10px!important;
        &:hover {
            background-color: $light-grey;

        }
    }
}

// Dropdown Button

.dropdownBtn {
    display: flex!important;
    padding: 10px!important;
    min-width: 100px;
    justify-content: space-around;
    align-items: center;
    border: none!important;
    p {
        font-size: 14px;
        &.grey {
            color: $grey;
        }
        &.green {
            color: $default-green;
        }
    }
    &.light-green {
        background-color: $light-green!important;
        color: $default-green!important;
        &:hover,&:active,&:focus {
            background-color: $default-green!important;
            color: $white!important;
            box-shadow: none!important;
            border: none!important;
            p {
                &.green {
                    color: $white!important;
                }
            }
        }
    }
    &.default-green {
        background-color: $default-green!important;
        color: $white!important;
        &:hover,&:active,&:focus {
            background-color: $dark-green!important;
            color: $white!important;
            box-shadow: none!important;
            border: none!important;
            p {
                &.green {
                    color: $white!important;
                }
            }
        }
    }
}

// Radio buttons

.mazhrRadio {
    label {
        display: flex;
        align-items: center;
        input[type="radio"] {
            cursor: pointer;
            appearance: none;
            background-color: $white;
            margin: 0;
            font: inherit;
            color: $default-green;
            width: 1.15em;
            height: 1.15em;
            border: 0.15em solid $default-green;
            border-radius: 50%;
            display: grid;
            place-content: center;
            margin-right: 5px;
        }
        input[type="radio"]::before {
            content: "";
            width: 0.65em;
            height: 0.65em;
            border-radius: 50%;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em $default-green;
        }
        input[type="radio"]:checked::before {
            transform: scale(1);
        }
        span {
            margin-left: 5px;
        }
    }
}

// Checkbox
.mazhrCheckbox {
    margin-bottom: 0;

    .checkbox-label {
        display: flex;
        position: relative;
        line-height: 135%;
        cursor: pointer;
        margin-bottom: 0;
    }

    .checkbox {
        position: relative;
        width: 0;
        height: 1rem;
        margin: 0 1rem 0 0 ;
        cursor: pointer;
        &:before {
              transition: all .3s ease-in-out;
              content: "";
              position: absolute;
              left: 0;
              z-index: 1;
              width: 1rem;
              height: 1rem;
              border: 1.5px solid $grey;
        }

        &:checked {
          &:before {
             transform: rotate(-45deg);
                height: .5rem;
                border-color: $default-green;
                border-top-style: none;
                border-right-style: none;
          }
        }

        &:after {
          content: "";
          position: absolute;
          top: -2rem;
          left: 0;
          width: 1rem;
          height: 1rem;
          background: transparent;
          cursor: pointer;
        }
      }
}

input[type=checkbox].hidden{
    opacity: 0;
  }

@keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
}

.blink {
    animation: blink 1s infinite;
}

@keyframes colorBlink {
    0% { color: $default-green; }
    50% { color: $dark-pink; }
    100% { color: $default-green; }
}

.color-blink {
    animation: colorBlink 1s infinite;
}

