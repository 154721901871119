.borderedBox {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 20px;
    border-radius: 12px;
    width: 100%;
}

.add-work-history {
    display: flex;
    align-items: center;
    justify-content: center;
}
.icon-svg {
    margin-right: 10px;
}

.flex-centered-div {
    display: flex;
    justify-content: space-evenly;
}
.ml-5 {
    margin-left: 5px;
}
.flex-mar-bottom {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
}
.checkbox-div {
    width: 270px;
    display: flex;
    justify-content: center;
}
.warning-text {
    text-align: left;
    margin-left: 25px;
    color: red;
}
.default-green-span {
    color: $default-green;
}
.p-dialog-mask.p-component-overlay {
    z-index: 1000 !important;
}

.invitation-acceptance-div {
    height: 100%;
    display: flex; 
    align-items: center; 
    justify-content: center;
    .invitation-acceptance-text {
        font-size: 24px;
        text-align: center;
    } 
}