.word-cloud {
    width: 90%;
}
.word-cloud__word {
    display: inline-block;
    font-size: 20px;
    line-height: 30px;
    color: $default-green;
}

.word-cloud__word--x-small,
.word-cloud__word--small {
    font-weight: 100;
}

.word-cloud__word--x-small {
    font-size: 20px;
    opacity: 0.3;
}

.word-cloud__word--small {
    font-size: 25px;
    opacity: 0.6;
}

.word-cloud__word--large {
    font-size: 30px;
    opacity: 0.7;
}

.word-cloud__word--x-large {
    font-size: 40px;
    letter-spacing: -0.0125em;
}