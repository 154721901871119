.wrapper-div {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}
.change-password-inner-div {
    width: 100%;
    margin: 0px 0px 20px 20px;
    background-color: white;
    padding: 20px; 
    border-radius: 10px;
}
.settings-page {
    width: 100%;
    .settings-page-wrapper {
        .settings {
            background: $white;
            padding: 20px;
            @media screen and (min-width: map-get($grid-breakpoints, 'sm')) {
                margin: 0px 0px 20px 20px;
                border-radius: 10px;
            }
            @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
            }
            @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
            }
            .form {
                .form-field {
                    .image-display {
                        display: flex;
                        padding: 10px;
                        border-radius: 10px;
                        .img {
                            width: 150px;
                            height: 150px;
                            position: relative;
                            overflow: hidden;
                            border-radius: 10px;
                            border: 1px dotted $grey;
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: scale-down;
                            }
                            .controls {
                                display: none;
                            }
                            &:hover {
                                img {
                                    opacity: 0.3;
                                }
                                .controls {
                                    display: block;
                                    position: absolute;
                                    width: 100%;
                                    height: 100%;
                                    top: 0;
                                    left: 0;
                                    label {
                                        cursor: pointer;
                                        width: 100%;
                                        height: 100%;
                                        .label-content {
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            width: 100%;
                                            height: 100%;
                                        }
                                    }
                                }
                            }
                        }
                        .remove-image {
                            display: flex;
                            align-items: flex-end;
                            margin-left: 10px;
                            margin-bottom: 3px;
                            cursor: pointer;
                        }
                    }
                }
            }
            .change-password-section {
                background: $light-green;
                padding: 25px;
                border-radius: 5px;
            } 
        }
        .settings-mobile {
            @media screen and (min-width: map-get($grid-breakpoints, 'xxl')) {
                display: none;
            }
        }
    }
}

@media (max-width: 1200px) {
    .change-password-inner-div {
        margin: 0px 0px 20px 0px;
    }
    .settings {
        margin: 0px 0px 20px 0px !important;
    }
  }


