.auth-page-wrapper {
    width: 100%;
    background-color: $default-green;
    .company-logo {
        display: none;
        @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
            display: block;
            max-width: 80px;
            max-height: 80px;
            position: absolute;
            z-index: 99;
            left: 10px;
            top: 10px;
        }

    }
    .login-form {
        padding: 4vh 5vw;
        z-index: 9;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 15px 15px 0 0;
        box-shadow: 0 -5px 16px 4px rgba(0,0,0,0.10);
        @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
            border-radius: 0 15px 15px 0;
            box-shadow: 5px 0 16px 4px rgba(0,0,0,0.10);
        }
        background: $white;
        @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
            padding: 0vh 3vw;
        }
        @media screen and (min-width: map-get($grid-breakpoints, 'xl')) {
            padding: 3vh 5vw;
        }
        @media screen and (min-width: map-get($grid-breakpoints, 'xxl')) {
            padding: 0vh 10vw;
        }
        .form {
            position: relative;
            .recover-password {
                bottom: 86px;
                right: 0;
            }
            .authButtons {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 20px;
                .mazhrBtn {
                    width: 48%;
                    &.full-width {
                        width: 100%;
                        @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
                            width: 50%;
                        }
                    }
                }
                .mazhrForgotPasswordBtn {
                    width: 100%;
                    &.full-width {
                        width: 100%;
                        @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
                            width: 50%;
                        }
                    }
                }
            }
        }
        .rememberMeWrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
        }
    }
}

