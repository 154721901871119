
.assessment-card-wrapper{
    display: flex;
    flex-wrap: wrap;
    .assessment-card {
        display: flex;
        align-items: center;
        border: 1px solid $grey;
        padding: 20px 10px;
        border-radius: 5px;
        flex: 1 0 30%;
        margin: 5px;
        height: 100px;
    }
}


.team-card-wrapper{
    display: flex;
    flex-wrap: wrap;
    .team-card {
        display: flex;
        border: 1px solid $grey;
        border-radius: 5px;
        padding: 10px;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        margin-bottom: 10px;
    }
}
