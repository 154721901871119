
.open-positions-no-data {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .no-data-illustration {
        max-width: 100%;
        margin-bottom: 30px;
    }
    .description {
        width: 400px;
    }
}

.add-open-position {
    background: $white;
    width: auto;
    border-radius: 10px 10px 0 0;
    padding: 20px;
    display: flex;
    justify-content: flex-end;
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
}
.open-position-filters {
    display: flex;
    padding: 15px 0;
    background: $background-green;
    .filter {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &.positions {
            width: 15%;
        }
        &.candidates {
            width: 16%;
        }
        &.deadline {
            width: 10%;
        }
        .filter-title {
            margin: 0 5px 0 0;
            font-size: 12px;
        }
    }
    .spacer {
        width: 55%;
    }
}

.pool-review-desktop{
    // @media screen and (min-width: map-get($grid-breakpoints, 'xxl')) {
    .add-pool {
        background: $white;
        width: 100%;
        margin-top: 30px;
        border-radius: 10px 10px 0 0;
        padding: 20px;
        display: flex;
        justify-content: flex-end;
        box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
    }
}

.open-positions {
    display: grid;
    grid-template-columns: 0.8fr 1.2fr;
    margin: 15px;
    .position-items {
        .position-items-wrapper {
            border: 1px solid $lighter-grey;
            .open-position {
                display: flex;
                align-items: center;
                width: 100%;
                background-color: $white;
                border: none;
                border-top: 1px solid $lighter-grey;
                padding: 15px;
                &.active{
                    background: $light-green;
                }
                &:first-child{
                    border: none;
                }
                .position-status {
                    min-width: 36px;
                    min-height: 36px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img {
                        width: 25px;
                        height: 25px;
                    }
                    &.active {
                        background-color: $default-green;
                    }
                    &.on-hold {
                        background-color: $default-yellow;
                    }
                    &.inactive {
                        background-color: $default-pink;
                    }
                }
                .position-desctription {
                    width: 32%;
                    display: flex;
                    margin-left: 10px;
                    .position-title {
                        overflow-wrap: break-word;
                        width: 100%;
                        text-align: left;
                    }
                }
                .mazhr-card-filled-candidates {
                    width: 35%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
                .deadline {
                    width: 25%;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                }
                .assessments-taken-candidates {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .candidates-number {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin-right: 10px;
                    }
                    img {
                        width: 25px;
                        height: 25px;
                    }
                }
            }
        }
    }
    .edit-field {
        padding: 5px 0 0 40px;
        border-top: 1px solid $lighter-grey;
        border-right: 1px solid $lighter-grey;
        .tab-content{
            .date{
                margin-right: 10px;
            }
        }
        .open-positions-edit {
            .basic-info {
                display: flex;
                justify-content: space-between;
                margin: 0 0 20px 0;
                .calls-to-action {
                    .buttons {
                        margin-bottom: 10px;
                        button {
                            &:first-child {
                                margin-right: 10px;
                            }
                        }
                    }
                    .copyJobLink {
                        display: flex;
                        justify-content: flex-end;
                    }
                }
            }
            .team-deadline {
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: flex-end;
            }
            .description {
                margin-right: 10px;
            }
            .recruitment-process {
                margin-right: 10px;
            }

        }
    }
}

.open-positions-mobile {
    @media screen and (min-width: map-get($grid-breakpoints, 'xxl')) {
        display: none;
    }
    .header-section {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        background: $white;
        padding: 20px;
        .icon {
            cursor: pointer;
            background: $light-green;
            padding: 15px;
            border-radius: 5px;
            img {
                width: 20px;
                height: 20px;
            }
        }
    }
    .filter-section {
        display: flex;
        justify-content: space-between;
        margin: 0 15px;
        .filters {
            background: $white;
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            img {
                width: 20px;
                height: 20px;
            }
        }
    }
    .accordion {
        margin: 10px;
        .accordion-item {
            border: none;
            .accordion-header {
                .accordion-button {
                    background-color: $white;
                    &.collapsed {
                        background-color: $lightest-green;
                    }
                    .open-position {
                        width: 100%;
                        .position-info {
                            display: flex;
                            .position-status {
                                width: 40px;
                                height: 40px;
                                border-radius: 50%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                margin-right: 15px;
                                img {
                                    width: 25px;
                                    height: 25px;
                                }
                                &.active {
                                    background-color: $default-green;
                                }
                                &.on-hold {
                                    background-color: $default-yellow;
                                }
                                &.inactive {
                                    background-color: $default-pink;
                                }
                            }
                            .position-desctription {
                                .position-title {
                                    text-transform: capitalize;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: map-get($grid-breakpoints, 'xxl')){
    .basic-info {
        display: flex;
        justify-content: space-between;
        margin: 0 0 20px 0;
        .calls-to-action {
            .buttons {
                margin-bottom: 10px;
                button {
                    &:first-child {
                        margin-right: 10px;
                    }
                }
            }
            .copyJobLink {
                display: flex;
                justify-content: flex-end;
            }
        }
    }
}

@media screen and (max-width: map-get($grid-breakpoints, 'sm')){
    .headers-btn{
        display: flex;
        flex-direction: column;
    }
}

@media screen and (max-width: map-get($grid-breakpoints, 'lg')){

    .open-position {
        flex-direction: column;
        .position-desctription{
            width: 100% !important;
            .position-title{
                text-align: center !important;
            }
        }
        .mazhr-card-filled-candidates{
            width: 100% !important;
        }
        .deadline{
            width: 100% !important;
            display: block !important;
        }
    }
    .open-position-filters {
        display: none !important;
    }
}
