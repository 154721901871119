.menu-item-label{
    font-size : 13px;
}
.menu-item-label-selected {
    margin-left: 10px;
    font-size: 20px;
    color: $default-green;
}

.close-button-div {
    display: flex;
    padding: 10px 10px 0 10px;
    justify-content: flex-end;
    background : #f8f9fa;
}

.close-btn {
    color: $default-green;
    cursor: pointer;
    margin-left: 10px !important;
    margin-top: 0 !important;
    padding: 0 !important;
    height: 35px;
}

.range-span {
    font-family: 'Raleway';
    font-style: italic;
    font-weight: 600;
    font-size: 14px;
    color: $hot-pink;
    padding: 20px 15px;
}

.chips-input {
    text-align: center;
    width:35px;
    border-radius: 5px;
    height: 25px;
    border: 2px solid $lightest-green;
}

.chips-input:focus-visible {
    border: 2px solid $default-green;
    outline: none;
}

.css-1162wj9-MuiFormControlLabel-root .MuiFormControlLabel-label {
    font-size: small !important;
}

.css-1xp34v-MuiInputBase-root-MuiOutlinedInput-root {

    height: 30px !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.search-input {
    margin-bottom : 10px;
    padding:10px;
    width: 95%;
    border-radius: 5px;
    height: 35px;
    border: 2px solid $lightest-green;

    &:focus-visible {
        border: 2px solid $default-green;
        outline: none;
    }
}

.filters-bttn{
    border: none;
    background-color: #fff;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: $default-green;
}
.save-filter-bttn{
    padding-left : 20px;
}

.filters-buttons-dd-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 30px;
    padding-top : 10px;
    width : 100%
}

.delete-filter-icon{
    color: rgba(19, 21, 21, 0.6);
}

.save-filter-dialog{
    width: 25vw;
    text-align: center;
    position : fixed !important;
    top:50%;
    left : 50%;
    transform : translate(-50%, -50%) !important;
    .info-heading{
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: #4A4A4A;
        text-align: left;
        padding-left : 30px;
        font-style: normal;
        padding-bottom: 8px;
        margin-bottom : 0;
    }
    .info-message{
        text-align: left;
        padding-left : 30px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #667085;
        font-style: normal;
        padding-top : 0;
        margin-top : 0;
    }
    .p-dialog-content{
        background: #FFFFFF !important;
        box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03) !important;
        border-radius: 12px !important;
    }
    .css-1xp34v-MuiInputBase-root-MuiOutlinedInput-root{
        height : 50px !important;
        width : 250px !important;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
    }
    .text-field{
        text-align: left;
        padding-left : 30px;
        padding-right : 30px;
        padding-bottom : 0px;
        width: 100%;
        .MuiOutlinedInput-root {
            background: #FFFFFF;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            border-radius: 8px;
        }
    }
    .icon-div{
        text-align: left;
        padding-left : 20px;
        padding-bottom : 10px;
    }
    .cancel-bttn{
        height : 45px;
        width: 175px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        background: #FFFFFF;
        border: 1px solid #D0D5DD;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        color: #344054;
    }
    .confirm-bttn{
        height : 45px;
        width: 175px !important;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 24px;
        background: #00B0A0;
        border: 1px solid #00B0A0;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
    }
}
