    #loader-2 span{
        display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background-color: $white;
    margin: 0 2px;
  }

  #loader-2 span:nth-child(1){
    animation: bounce 1s ease-in-out infinite;
  }

  #loader-2 span:nth-child(2){
    animation: bounce 1s ease-in-out 0.33s infinite;
  }

  #loader-2 span:nth-child(3){
    animation: bounce 1s ease-in-out 0.66s infinite;
  }

  @keyframes bounce{
    0%, 75%, 100%{
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      -o-transform: translateY(0);
      transform: translateY(0);
    }

    25%{
      -webkit-transform: translateY(-10px);
      -ms-transform: translateY(-10px);
      -o-transform: translateY(-10px);
      transform: translateY(-10px);
    }
  }
