.user-list-wrapper-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.margin-left-div {
    margin: 0px 0px 20px 20px;
}
.user-invite-div {
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    background-color: white;   
}
.user-invite-flex-end-div {
    display: flex;
    align-items: flex-start;
    & > div {
        margin-right: 10px;
    }
}
.user-invite-error-div {
    width: 100%;
    color: red;
    margin-top: 10px;
}
.user-list-inner-div {
    background-color: white;
    padding: 20px; 
    border-radius: 10px;
}

@media (max-width: 1200px) {
    .margin-left-div {
        margin: 0px 0px 20px 0px;
    }
  }