.react-datepicker__input-container {
    input {
        border-radius: 5px;
        box-shadow: none;
        border: 1px solid $grey;
        text-align: center;
        padding: 5px 0;
        cursor: pointer;
        &:focus-visible {
            outline: none;
        }
    }
}

.react-datepicker {
    .react-datepicker__current-month {
        font-family: 'Raleway', sans-serif;
        color: $default-green;
        font-weight: 400;
        letter-spacing: 1px;
    }
    .react-datepicker__header  {
        background-color: $light-green;
    }
    .react-datepicker__triangle {
        display: none;
    }
    .react-datepicker__day:hover {
        background-color: $light-green;
    }
    .react-datepicker__day--selected,
    .react-datepicker__day--keyboard-selected {
        background-color: $default-green;
        &:hover {
            background-color: $default-green;
        }
    }
}
