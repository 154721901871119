.page-layout-wrapper {
    width: 100%;
    .inner-layout {
        display: flex;
        justify-content: center;
        width: auto;
        margin: 1rem;
        background: transparent;
        border-radius: 12px;
    }
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    background-color: #e0f4f1 !important;
    color: #000000 !important;
}

@media (max-width: 1200px) {
    .vp-1200 {
        flex-direction: column;
    }
  }