.copy-wrapper{
  display: flex;
  width: 55%;
  .content{
    .heading{
      font-size:20px;
      font-weight:700
    }
    .text-body{
      font-size:14px;
      font-weight:400;
    }
    .button-wrapper{
      display: flex;
      justify-content: flex-start;
    }
  }
}

.form-wrapper{
  width: 55%;
  margin-top:55px;
  #feedback-form{
    width: 100%;
    background: white;
    padding: 30px;
    border-radius: 5px;
  }
  .heading{
    font-size:20px;
    font-weight: 400;
    margin-bottom:20px;
  }
}
