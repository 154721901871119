.loading {
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $white;
    .gif {
        z-index: 99;
        width: 40px;
        height: 40px;
    }
}

.loader {
    width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 50%;
}