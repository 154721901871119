.score-line-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    .point {
        background: $light-grey;
        height: 5px;
        margin-right: 3px;
        border-radius: 15px;
        &:last-child {
            margin-right: 0;
        }

        &.xxs {width: 7px;}
        &.xs {width: 11px;}
        &.s {width: 14px;}
        &.md {width: 17px;}
        &.lg {width: 27px;}

        &.active {
            &.weak {background-color: $default-pink;}
            &.medium {background-color: $default-yellow;}
            &.strong {background-color: $default-green;}
        }
    }
}
