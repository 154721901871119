.inactive-wrapper {
    width: 100%;
    position: relative;
    .css-8atqhb {
        position: fixed;
    }
    .center-content {
        width: 100%;
        height: 100%;
    }
}

.hide {
    display: none!important;
}

.show {
    display: block!important;
    &.flex {
        display: flex!important;
    }
}
.field-label{
    padding-bottom : 5px;
    font-size :14px;
    color : $dark_grey;
    .italic {
        font-style:italic;
    }
}

.m-r-5{
    margin : 0 5px 0 0;
}

@keyframes blink-animation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.blinking-button {
    animation: blink-animation 1s infinite;
}

@keyframes blink-animation {
    0% {
        box-shadow: 0 0 0 2px $dark-yellow;
    }
    50% {
        box-shadow: 0 0 0 5px transparent;
    }
    100% {
        box-shadow: 0 0 0 2px $hot-pink;
    }
}

.blinking-border-button {
    animation: blink-animation 2s infinite;
    border: none;
    outline: none;
}

.mandatory{
    color :$hot-pink;
    font-size : 25px;
    padding: 0;
    margin: 0 0 0 -5px;
}

.unsaved-changes {
    background: linear-gradient(to right, rgba(255, 0, 0, 0), $hot-pink, $hot-pink, rgba(255, 0, 0, 0));
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: $white;
    text-align: center;
    padding: 10px;
    text-transform: uppercase;
    .cta-txt{
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: $white;
        text-transform: none;
    }
}
