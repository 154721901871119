@import "primeicons/primeicons.css";


.custom-checkbox {
    position: relative;
    top: 5px;
    margin-right: 0.5rem;
    cursor: pointer;
     .checkmark {
       width: 21px;
       height: 21px;
       border: 2px solid #00B0A0;
       display: inline-block;
       border-radius: 3px;
       background: #00B0A0 url('../../../assets/images/icons/industriesIcons/white_check.png') center/1250% no-repeat;
    }
     input{
       display: none;
    }

    input:checked + .checkmark{
        background-size: 60%;
    }
}

.main-div{
    padding: 1rem;
    background: white;
    width: 100%;
}

.header{
    color: #1B7971;
    text-align: center;
    font-weight: bold;
}

.dropdowns{
    display: flex;
    justify-content: space-between;
    margin: 2rem 0;
}

.p-dropdown.p-component.p-inputwrapper.p-inputwrapper-filled.dropdowns.mb-2.w-2 {
    display: none !important;
  }
