.recruitment-process {
    .title {
        margin-bottom: 30px;
    }
    .selectedList {
        .process-item {
            display: flex;
            align-items: center;
            .item-number {
                margin: 0 15px 0 0;
                border: 1px solid $default-green;
                color: $default-green;
                height: 25px;
                background: $light-green;
                width: 27px;
                padding-bottom: 2px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
            }
            .item-content {
                width: 100%;
                display: flex;
                justify-content: space-between;
                border: 1px solid $grey;
                margin: 5px 0;
                padding: 5px 10px;
                border-radius: 5px;
                align-items: center;
                background: $white;
                .item, .remove {
                    margin: 0;
                    padding: 0;
                    cursor: pointer;
                }
                .remove {
                    .exit {
                        font-size: 25px;
                    }
                    &:hover {
                        color: $default-pink;
                    }
                }
                &:hover {
                    background: $light-grey;
                }
            }
        }
    }

    .more-options-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
        .plus-icon {
            font-size: 18px;
            margin-right: 8px;
        }
    }

    .custom-option-form {
        position: relative;
        border: 1px solid $default-green;
        border-radius: 5px;
        padding: 20px;
        .exit {
            margin: 0;
            position: absolute;
            right: 10px;
            top: 10px;
            color: $default-green;
            font-size: 25px;
            cursor: pointer;
        }
    }

    .not-selected-list {
        display: flex;
        flex-wrap: wrap;
        margin-top: 30px;
        position: relative;
        &.show-border {
            border: 1px dashed $default-green;
            padding: 20px;
            border-radius: 5px;
        }
        .exit {
            margin: 0;
            position: absolute;
            right: 10px;
            top: 10px;
            color: $default-green;
            font-size: 25px;
            cursor: pointer;
        }
        .process-item {
            display: flex;
            border: 1px solid $grey;
            margin: 5px 10px  5px 0;
            padding: 5px 10px;
            border-radius: 5px;
            width: auto;
            justify-content: center;
            align-items: center;
            .item {
                margin: 0;
                padding: 0;
                cursor: pointer;
            }
            &:hover {
                background: $light-grey;
            }
        }
    }
}
