.new-open-position {
    .title{
        margin: 0 0 40px 0;
    }
    background-color: $white;
    margin: 0 auto;
    width: 100%;
    padding: 30px;
    border-radius: 5px;
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;

    @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
        margin: 30px auto;
        width: 80%;
    }
    @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
        width: 70%;
    }
    @media screen and (min-width: map-get($grid-breakpoints, 'xl')) {
        width: 60%;
    }
    @media screen and (min-width: map-get($grid-breakpoints, 'xxl')) {
        width: 50%;
    }

}


.exit {
    display: flex;
    justify-content: flex-end;
    .icon {
        cursor: pointer;
        display: inline-block;
        width: 30px;
        height: 30px;
        background-size: cover;
    }
    .icon-exit {
        background-image: url('../assets/images/icons/green-exit.svg');
      }
}
