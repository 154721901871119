.explanation-tooltip {
    position: relative;
    top: -7px;
    font-size: 13px;
    cursor: pointer;
    color: $grey;
    margin-left: 3px;
    &:hover {
        color: $default-green;
    }
}