.nav.nav-tabs {
    display: block;
    @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
        display: flex;
    }
}

.nav-tabs {
    --bs-nav-tabs-border-width: 0 !important;
    &:focus-visible {
        outline: none;
    }
    &.center {
        justify-content: center;
    }
    .nav-item {
        cursor: pointer;
        border: none;
        margin: 0 20px 0 25px;
        &:focus-visible {
            outline: none!important;
        }
        .nav-link {
            font-weight: 400;
            color: $black;
            border: none;
            text-transform: uppercase;
            outline: none;
            font-size: 14px;
            &:focus {
                border: none;
            }
            &:focus-visible {
                outline: none!important;
            }
            &.active {
                border-top: 0;
                border-right: 0;
                border-left: 0;
                background-color: #f0f4f4;
                border-bottom: 2px solid $default-green;
            }
            &:hover {
                border-top: 0;
                border-right: 0;
                border-left: 0;
                border-bottom: 2px solid $black;
            }
        }
    }
}

.nav-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
}
