// BW SCALE
$white: #ffffff;
$light-grey: #ECECF2;
$lighter-grey: #F2F2F2;
$grey: #979797;
$dark-grey: #575757;
$black: #000000;
$blackish:#131515;

// pinkS
$hot-pink : #FE6262;
$default-pink: #C13F8A;
$dark-pink: #9A326F;
$medium-pink: #D274A8;
$lighter-pink: #E6B2CF;
$light-pink: #F0D1E2;
$lightest-pink: #f7e1ed;


// GREENS
$default-green: #00B0A0;
$dark-green: #004740;
$medium-green: #0F9B8F;
$lighter-green: #8FD3CB;
$light-green: #E0F4F1;
$lightest-green: #d4f4f1;
$background-green: #edf1f0;


// PURPLES
$default-purple: #8049B0;
$dark-purple: #543073;
$medium-purple: #9C6FC3;
$lighter-purple: #CFB7E1;
$light-purple: #E2D4ED;

// YELLOWS
$default-yellow: #FFC247;
$medium-yellow: #FFBB31;
$dark-yellow: #FFAD0A;
$lighter-yellow: #FFD685;
$light-yellow: #FFE4AD;

$toastify-color-success: #8FD3CB;

* {
    &.white {
        color: $white!important;
    }
    &.black {
        font-weight: 900;
    }
    &.light-grey {
        color: $light-grey!important;
    }
    &.grey {
        color: $grey!important;
    }
    &.green {
        color: $default-green;
    }
    &.dark-green {
        color: $dark-green;
    }
    &.pink {
        color: $default-pink;
    }
    &.medium-pink {
        color: $medium-pink;
    }
    &.dark-pink {
        color: $dark-pink;
    }
    &.yellow {
        color: $default-yellow;
    }
    &.purple {
        color: $default-purple;
    }
    &.background-green {
        background-color: $background-green;
    }
}

