.css-161tz65-MuiFormHelperText-root.Mui-error {
    margin: 10px auto;
    background: $lightest-pink;
    border-radius: 5px;
    width: 100%;

    color: $default-pink!important;
    padding: 20px!important;
    font-size: 14px!important;
}

.css-j9jfr0-MuiFormHelperText-root.Mui-error {
    margin: 10px auto;
    background: $lightest-pink;
    border-radius: 5px;
    width: 100%;

    color: $default-pink!important;
    padding: 20px!important;
    font-size: 14px!important;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
    color: $default-green!important;
}
