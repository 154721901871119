.auth-mobile-header {
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden;
    .title-wrapper {
        color: $white;
        position: absolute;
        width: 80%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 9;
        .sub-title {
            margin-top: 20px;
            text-align: center;
        }
    }
    .decoration-wrapper {
        height: 40vh;
        overflow: hidden;
        .blob {
            position: absolute;
            min-width: 130%;
            &.lighter {
                left: -14rem;
                top: 3rem;
            }
            &.darker {
                left: -9rem;
                top: 5rem;
            }
        }
    }
}