.overlay {
    &.active {
        background: $black;
        width: 100vw;
        height: 100vh;
        position: absolute;
        right: 0;
        top: 0;
        opacity: 0.7;
    }
}