.assessments-wrapper {
    .poolToggler {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }
}

.titleSection {
    display: flex;
    justify-content: space-between;
    .title {
        width: 50%;
    }
    .date {
        p {
            font-size: 11px;
        }
    }
    .poolToggler {
        display: flex;
        align-items: center;
        border: 1px solid $light-green;
        padding-right: 15px;
        border-radius: 3px;
    }
}
.description {
    .description-input {
        margin-top: 20px;
        border-bottom: 1px solid $grey;
        padding-bottom: 10px;
        cursor: text;
    }
}
.departmentSection {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    .department {
        margin-right: 20px;
    }
    .targetProfile {
        .explanation-tooltip {
            //display: flex;
            //width: 100%;
            justify-content: flex-end;
        }
    }
}

