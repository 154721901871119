.team-audit-page {
    width: 100%;
    .team-audit-no-data {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .no-data-illustration {
            max-width: 100%;
            margin-bottom: 30px;
        }
        .description {
            width: 400px;
        }
    }
}