// RALEWAY

@font-face {
    font-family: "Raleway";
    src: url("../assets/fonts/raleway/raleway-extralight-webfont.woff2") format("woff2"),
    url("../assets/fonts/raleway/raleway-extralight-webfont.woff") format("woff"),
    url("../assets/fonts/raleway/raleway-extralight-webfont.ttf") format("truetype");
    font-weight: 200;
    font-style: normal;
}
  
@font-face {
    font-family: "Raleway";
    src: url("../assets/fonts/raleway/raleway-light-webfont.woff2") format("woff2"),
    url("../assets/fonts/raleway/raleway-light-webfont.woff") format("woff"),
    url("../assets/fonts/raleway/raleway-light-webfont.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}
  
@font-face {
    font-family: "Raleway";
    src: url("../assets/fonts/raleway/raleway-medium-webfont.woff2") format("woff2"),
    url("../assets/fonts/raleway/raleway-medium-webfont.woff") format("woff"),
    url("../assets/fonts/raleway/raleway-medium-webfont.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}
  
@font-face {
    font-family: "Raleway";
    src: url("../assets/fonts/raleway/raleway-bold-webfont.woff2") format("woff2"),
    url("../assets/fonts/raleway/raleway-bold-webfont.woff") format("woff"),
    url("../assets/fonts/raleway/raleway-bold-webfont.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}
  
@font-face {
    font-family: "Raleway";
    src: url("../assets/fonts/raleway/raleway-extrabold-webfont.woff2") format("woff2"),
    url("../assets/fonts/raleway/raleway-extrabold-webfont.woff") format("woff"),
    url("../assets/fonts/raleway/raleway-extrabold-webfont.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
}
  
@font-face {
    font-family: "Raleway";
    src: url("../assets/fonts/raleway/raleway-black-webfont.woff2") format("woff2"),
    url("../assets/fonts/raleway/raleway-black-webfont.woff") format("woff"),
    url("../assets/fonts/raleway/raleway-black-webfont.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}


// M PLUS ROUNDED 1c

@font-face {
    font-family: "MPLUSRounded1c";
    src: url('../assets/fonts/mplusRounded1c/MPLUSRounded1c-Thin.woff2') format('woff2'),
    url('../assets/fonts/mplusRounded1c/MPLUSRounded1c-Thin.woff') format('woff'),
    url('../assets/fonts/mplusRounded1c/MPLUSRounded1c-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: "MPLUSRounded1c";
    src: url('../assets/fonts/mplusRounded1c/MPLUSRounded1c-Light.woff2') format('woff2'),
    url('../assets/fonts/mplusRounded1c/MPLUSRounded1c-Light.woff') format('woff'),
    url('../assets/fonts/mplusRounded1c/MPLUSRounded1c-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "MPLUSRounded1c";
    src: url('../assets/fonts/mplusRounded1c/MPLUSRounded1c-Regular.woff2') format('woff2'),
    url('../assets/fonts/mplusRounded1c/MPLUSRounded1c-Regular.woff') format('woff'),
    url('../assets/fonts/mplusRounded1c/MPLUSRounded1c-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "MPLUSRounded1c";
    src: url('../assets/fonts/mplusRounded1c/MPLUSRounded1c-Medium.woff2') format('woff2'),
    url('../assets/fonts/mplusRounded1c/MPLUSRounded1c-Medium.woff') format('woff'),
    url('../assets/fonts/mplusRounded1c/MPLUSRounded1c-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "MPLUSRounded1c";
    src: url('../assets/fonts/mplusRounded1c/MPLUSRounded1c-Bold.woff2') format('woff2'),
    url('../assets/fonts/mplusRounded1c/MPLUSRounded1c-Bold.woff') format('woff'),
    url('../assets/fonts/mplusRounded1c/MPLUSRounded1c-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "MPLUSRounded1c";
    src: url('../assets/fonts/mplusRounded1c/MPLUSRounded1c-ExtraBold.woff2') format('woff2'),
    url('../assets/fonts/mplusRounded1c/MPLUSRounded1c-ExtraBold.woff') format('woff'),
    url('../assets/fonts/mplusRounded1c/MPLUSRounded1c-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "MPLUSRounded1c";
    src: url('../assets/fonts/mplusRounded1c/MPLUSRounded1c-Black.woff2') format('woff2'),
    url('../assets/fonts/mplusRounded1c/MPLUSRounded1c-Black.woff') format('woff'),
    url('../assets/fonts/mplusRounded1c/MPLUSRounded1c-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}