.role-wrapper-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.role-invite-div {
    justify-content: space-between;
    padding: 0 10px 10px 10px;
    margin-bottom: 20px;
    border-radius: 10px;
    background-color: white;
}
.custom-margin-div {
    margin: 0px 0px 20px 20px;
}
.disp-flex {
    display: flex;
    flex-direction: column; /* Display children in a column */
}

.role-dropdown{
    padding: 0 10px 10px 10px;
}
.config-note{
    color : $dark-pink;
    font-size : 16px;
    padding: 10px 10px 0 10px;
}
.resize-textfield {
    width: 250px;
    margin-left: 10px;
}
.role-title-div {
    background-color: #e0f4f1;
    margin: 0px;
    padding: 10px 20px 10px 10px;
    text-transform: uppercase;
    font-weight : bold;
}
.grid-container {
    padding: 0px 0px 20px 5px;
}
.permissions-grid-view {
    display: grid;
    grid-template-columns: 200px auto;
    /*border-bottom: 1px solid lightgrey;*/
    gap: 20px;
    padding: 10px 0;
}
.checkboxes-grid-view {
    display: flex;
    flex-wrap: wrap;

    @media screen and (max-width: 768px) {
        /* For screens up to 768px wide, change the layout to 2 columns */
        // grid-template-columns: repeat(2, 250px);
    }

    @media screen and (max-width: 480px) {
        /* For screens up to 480px wide, change the layout to 1 column */
        // grid-template-columns: repeat(1, 250px);
    }
}

.checkboxes-grid-view > div {
    width: fit-content;
    text-wrap: nowrap;
    padding: 0 20px;
}

.role-button-div {
    display: flex;
    justify-content: flex-end;
}
.user-list-inner-div {
    background-color: white;
    padding: 10px;
    border-radius: 10px;
}
.flex-end-div {
    display: flex;
    align-items: flex-end;
}

.disable-pointer{
    pointer-events: none; /* Prevent interactions with child elements */
    opacity: 0.6;
}

@media (max-width: 1200px) {
    .custom-margin-div {
        margin: 0px 0px 20px 0px;
    }
  }
