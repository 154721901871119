
.MuiPaper-root{
    padding-top: 0;

}
.Mui-expanded {
    background-color: $background-green; /* Set the background color when the accordion is expanded */


    .MuiAccordionSummary-root{
        color: $default-green;
    }
    .MuiAccordionDetails-root{
        padding : 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0px;
        text-align: left;
        color: $blackish;
        //background-color: $background-green; /* Set the background color when the accordion is expanded */
    }
}
.img-div {
    display: flex;
    justify-content: center;
    position: relative;
    //background-color: $white;
    height: 100%;
    background-image: url('../assets/images/backgrounds/bg.png');
    background-repeat: no-repeat;
    background-size: auto;
    background-position-x: center;
    background-position-y: -60px;
}

.tabs-bottom-alignment{
    position: absolute;
    top: 215px !important;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    .tab-content{
        background-color : $white;
        .accordion-main-div {
            display: flex;
            justify-content: center;
            margin-top: 50px;
            .accordion-wrapper-div {
                max-width : 882px;
                height: 400px;
                overflow-y: auto;
                .MuiAccordionSummary-root{
                    padding : 0;
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 27px;
                    letter-spacing: 0px;
                    text-align: left;
                    .MuiAccordionSummary-expandIconWrapper .MuiSvgIcon-root{
                        color : $medium-green;
                    }
                }
            }
        }
    }

    ul {
        justify-content: center !important;
        li {
            flex: none !important;

        }
    }
    .nav{
        margin-bottom : 30px;
        color: $blackish;
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0px;
        text-align: center;
    }

    .nav-tabs .nav-item .nav-link{
        text-transform: none !important;
        font-weight: 500;
        &.active {
            background-color: $white !important;
            border-radius: 10px !important;
            border: 2px solid $lighter-green !important;
        }
        &:not(.active):hover {
            /* Define the hover styles here */
            /* For example, change the background color and text color */
            border-radius: 10px !important;
            border: 2px solid $light-grey !important;
        }
        &:focus-visible {
            outline: none!important;
        }
        transition: none !important;
}
    transition: none !important;
}
.first-head {
    position: absolute;
    top: 100px;
    .first-head-text {
        font-size: 32px;
        font-weight: 700;
        line-height: 42px;
        letter-spacing: 0px;
        text-align: center;
    }
}

.css-11bpk76-MuiPaper-root-MuiAccordion-root {
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 1px rgba(0,0,0,0.2), 0px 1px 3px 0px rgba(0,0,0,1) !important;
}
.css-o4b71y-MuiAccordionSummary-content {
    margin: 12px 0px 0px 0px !important;
}

.question-accordion {
    font-size: 18px;
}
.answer-accordion {
    font-size: 14px;
    ol{
        padding-left : 15px;
        padding-top : 0;
    }
    li{
        list-style:decimal;
    }
}
