.pagination {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 25px;
    .previous {
        margin-right: 15px;
        background-color: $lighter-grey;
        .prevBtn  {
            color: $black;
            font-size: 12px;
            font-weight: 700;
        }
    }
    .next {
        margin-left: 15px;
        .nextBtn {
            color: $black;
            font-size: 12px;
            font-weight: 700;
        }
    }
    .previous,
    .next {
        background-color:  $lighter-grey;
        border-radius: 4px;
        width: 85px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        &.paginationDisabled {
            background-color: $light-grey;
            cursor: not-allowed;
            a {
                color: $grey;
                cursor: not-allowed;
            }
        }
    }
    .pagination-item,
    .break {
        background-color: $lighter-grey;
        color: $black;
        margin: 0 5px;
        width: 35px;
        height: 35px;
        border-radius: 4px;
        &.paginationActive {
            background-color: $light-green;
            color: $default-green;
        }
        a {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .break {
        background-color: $lighter-grey;
        color: $black;
    }
}
