.help-center {
    .hubspot-form-div{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    #helpCenterHbForm{
        width: 800px;
        background: white;
        padding: 30px;
        border-radius: 5px;
    }
}
