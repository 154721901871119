.editor-wrapper {
    border: 1px solid $default-green;
    border-radius: 5px;
    .editor-toolbar {
        padding: 10px;
        background: transparent;
        border-radius: 5px 5px 0 0;
        border-bottom: 1px solid $default-green;
        .rdw-option-wrapper {
            border: none;
            &:hover {
                box-shadow: none;
            }
        }
        .rdw-dropdown-wrapper {
            .rdw-dropdown-selectedtext {
                color: $black;
            }
            &:hover {
                box-shadow: none;
            }
        }

    }
    .editor {
        min-height: 200px;
        padding: 0 15px;
    }
}
