
.main-div-team-simulator {
  display: flex;
  justify-content: center;
}

.bg-div {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  background-color: #f0f4f4;
}

.box-main-div {
  width: 100%;
  display: flex;
  padding: 0 10px 0 10px;
}

.box-div {
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  margin-right: 10px;
  height: 150px;
  overflow-y: scroll;
  width: 100%;
  /* This will enable vertical scrolling for the div */

  /* Hide scrollbar for all browsers except Firefox */
  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for Firefox */
  background-color: #ffffff;
}

.for-candidates{
  border : 1px solid $lighter-yellow
}

.for-employees{
  border : 1px solid $lighter-green
}

.box-div::-webkit-scrollbar {
  display: none;
}

.box-div-secondary {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  margin-top: 35px;
  border-radius: 10px;
  padding: 10px 5px;
  height: 300px;
  background-color: #ffffff;
  width:100%
}

.box-div-secondary::-webkit-scrollbar {
  display: none;
}

.dropdown-div {
  width: 100%;
  display: flex;
  margin-top: 20px;
  margin-bottom:20px;
  flex-direction: row;
  padding-left : 10px;
}

.select-pool-dropdown{
  .css-11axs4r{
    max-width: 200px;
    margin-right: 5px;
    text-align: center;
  }
}
.select-team-dropdown{
  .css-11axs4r{
    max-width: 200px;
    margin-left: 5px;
    text-align: center;
  }
}


.secondary-div {
  width: 40%;
}

.cancel-button-div {
  margin: 10px 0 0 0;
  display: flex;
  justify-content: flex-end;
}

.horizontal-line {
  height: 100%;
  border: 1px solid;
  margin-top: 0;
}

.user-list-box {
  padding: 0;
  opacity: 1;
  transform: none;
  min-width: 200px;
  transition: opacity 262ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 174ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  top: 0;
  left: 0;
  transform-origin: 125px 0;
  border-radius: 4px;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  // max-width: 250px;
  outline: 0;
  background-color: #fff;

 .css-eldkgm-MuiButtonBase-root-MuiMenuItem-root:hover {
  width: 100vw !important;
 }
}

.front-div {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.charts-div-background {
  background-color: #f0f4f4;
  padding: 10px;
}

.charts-div {
  background-color: white;
  border-radius: 25px;
  display: flex;
  padding: 20px;
  margin-top: 10px;
}

.radar-charts-div {
  display: flex;
  background: white;
  width: 100%;
  margin: 0;
  padding: 0;
  align-items: center;
  justify-content: flex-start;
}

.vertical-chart-div {
  background: white;
  width: 100%;
  justify-content: flex-start;
  height: 300px;
  display: flex;
  align-items: center;
  padding: 25px 25px 25px 0;
}

.middle-div {
  display: flex;
  align-items: center;
  margin-top: 50px;
}

.p-dialog {
  max-height: 97% !important;
}

.p-dialog-content {
  // overflow-y: hidden !important;
}

// .p-dialog-mask.p-component-overlay {
//   justify-content: flex-end;
// }

.button-div {
  display: flex;
  margin-top: 10px;
  justify-content: flex-end;
  background-color: white;
}

.team-simulator-modal-button {
  border: none;
  color: white;
  background-color: $medium-green;
  height: 35px;
  width: 180px;
  border-radius: 0 0 13px 13px;
  position: absolute;
  right: 90px;
  margin-top: -3px;
  font-size: 14px;
  font-weight: 600;
}

.modal-123 {
  z-index: 100;
  position: absolute;
  bottom: calc(100%);
  width: 58.5%;
  transform: translateY(0);
  transition: all 1s;
  border-radius:5px;
}

.modal-visible {
  transform: translateY(0);
}
.modal-revamp-class {
  max-height: calc(100vh - 50px);
  overflow-y: auto;
  transition: all 1s;
}

.modal-visible-animated {
  background-color: #f0f4f4;
  transform: translateY(calc(100%));
  box-shadow: $dark-green -5px 5px 15px, $dark-green 0px 7px 13px -3px, $dark-green 0px -3px 0px inset;
}

.modal-close-bttn{
  color: $default-green;
  font-size: 35px !important;
  cursor: pointer;
}
